import React from "react"
import {
  TiSocialFacebook,
  TiSocialInstagram,
} from "react-icons/ti"

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="footer-social">
          <div className="footer-social-text">Follow Us</div>
          <div className="footer-social-icons">
            <a
              target="_blank"
              href="https://www.instagram.com/cafebagsblog/"
              rel="noopener noreferrer"
            >
              <span className="icon-container" id="ig-icon">
                <TiSocialInstagram className="footer-social-icon" />
              </span>
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/Cafebags-100960921740027/"
              rel="noopener noreferrer"
            >
              <span className="icon-container" id="fb-icon">
                <TiSocialFacebook className="footer-social-icon" />
              </span>
            </a>
          </div>
        </div>
        <div>© {new Date().getFullYear()} CafeBags</div>
      </div>
    </footer>
  )
}
export default Footer
