import React from "react"
import CookieConsent from 'react-cookie-consent'

const CookieForm = () =>{
    return (
        <CookieConsent  
            location="top" 
            buttonText="Accept" 
            enableDeclineButton="true" 
            declineButtonText="Decline" 
            flipButtons="true"
            cookieName="gatsby-gdpr-google-tagmanager"
            style={{ "margin-top": "65px"}} 
           
            >

            This site uses cookies ...
        
        </CookieConsent>
    )
}

export default CookieForm